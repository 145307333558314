<template>
  <b-card no-body class="card-statistics">
    <!-- <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text>
    </b-card-header> -->
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="success">
                <feather-icon size="24" icon="AlignJustifyIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ total_appointment }}</h4>
              <b-card-text class="font-small-3 mb-0">
                Total Appointment
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="warning">
                <feather-icon size="24" icon="ListIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ pending_appointment }}</h4>
              <b-card-text class="font-small-3 mb-0">
                Pending Appointment
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="success">
                <feather-icon size="24" icon="CheckCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ approved_appointment }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Approved Appointment
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <feather-icon size="24" icon="XIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ reject_appointment }}</h4>
              <b-card-text class="font-small-3 mb-0">
                Reject Appointment
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
export default {
  data() {
    return {
      total_appointment: 0,
      pending_appointment: 0,
      approved_appointment: 0,
      reject_appointment: 0,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults() {
      const appointment = await this.callApi(
        "post",
        "/app/appointment/over/view"
      );
      if (appointment.status == 200) {
        this.total_appointment = appointment.data.total_appointment;
        this.approved_appointment = appointment.data.approved_appointment;
        this.pending_appointment = appointment.data.pending_appointment;
        this.reject_appointment =
          appointment.data.total_appointment -
          (appointment.data.pending_appointment +
            appointment.data.approved_appointment);
      }
    },
  },
};
</script>

<style></style>
