<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show </label>
            <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
            <v-select
              @on-change="paginateChange()"
              :options="perPageOptions"
              v-model="search.paginate"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search.search_data"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <v-select
              id="type"
              v-model="search.type"
              placeholder="Select Person Type"
              :options="types"
              label="name"
              :reduce="(option) => option.value"
            >
              <template #option="{ name }">
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-col>
          <b-col cols="12" md="2">
            <v-select
              id="status"
              v-model="search.status"
              placeholder="Select Status"
              :options="status"
              label="name"
              :reduce="(option) => option.value"
            >
              <template #option="{ name }">
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center align-middle">SL</th>
              <th class="align-middle">User Info</th>
              <th class="align-middle text-center">Type</th>
              <th class="align-middle">Contact Info</th>
              <th class="align-middle">Desire Date and Time</th>
              <th class="align-middle text-center">Status</th>
              <th class="align-middle text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(appointment, index) in appointments.data"
              v-if="appointments.data.length"
            >
              <td class="text-center align-middle">
                {{ index + appointments.from }}
              </td>
              <td>
                <div class="font-weight-bolder" v-if="appointment.name">
                  {{ appointment.name }}
                </div>
                <div
                  class="font-small-2 text-muted"
                  v-if="appointment.designation"
                >
                  {{ appointment.designation }}
                </div>
              </td>
              <td class="text-center align-middle">
                <b-badge
                  :variant="appointment.type == 1 ? 'success' : 'primary'"
                  class="text-capitalize"
                >
                  {{ appointment.type == 1 ? "General People" : "Police" }}
                </b-badge>
              </td>
              <td class="align-middle">
                <p v-if="appointment.email" class="mb-0">
                  <b>Email : </b>{{ appointment.email }}
                </p>
                <p v-if="appointment.phone">
                  <b>Phone : </b>{{ appointment.phone }}
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b> {{ appointment.desire_date_time | date_time_format }}</b>
                </p>
                <p class="mb-0 font-small-2 text-muted">
                  Created At : {{ appointment.created_at | date_time_format }}
                </p>
              </td>
              <td class="text-center align-middle">
                <b-badge
                  :variant="
                    appointment.is_approved == 1
                      ? 'success'
                      : appointment.is_approved == 2
                      ? 'warning'
                      : 'danger'
                  "
                  class="text-capitalize"
                >
                  {{
                    appointment.is_approved == 1
                      ? "Approved"
                      : appointment.is_approved == 2
                      ? "Pending"
                      : "Reject"
                  }}
                </b-badge>
              </td>
              <td class="text-center align-middle">
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  @click="getAppointmentModel(appointment.id)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ appointments.from }} to {{ appointments.to }} of
              {{ appointments.total }} entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="appointments"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <Modal
      v-model="appointmentModle"
      title="Appointment View"
      footer-hide
      width="700"
      :mask-closable="false"
      :closable="false"
    >
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th width="220">Heading</th>
              <th>Information</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="appointment_data.user">
              <th class="align-middle">Appoint For</th>
              <td class="align-middle">
                {{ appointment_data.user.designation }} ({{
                  appointment_data.user.name
                }})
              </td>
            </tr>
            <tr v-if="appointment_data.bpn_civ_number">
              <th class="align-middle">BPN / CIV Number</th>
              <td class="align-middle">
                {{ appointment_data.bpn_civ_number }}
              </td>
            </tr>
            <tr v-if="appointment_data.name">
              <th class="align-middle">Name</th>
              <td class="align-middle">{{ appointment_data.name }}</td>
            </tr>
            <tr v-if="appointment_data.designation">
              <th class="align-middle">Designation</th>
              <td class="align-middle">{{ appointment_data.designation }}</td>
            </tr>
            <tr v-if="appointment_data.type">
              <th class="align-middle">User Type</th>
              <td class="align-middle">
                <b-badge
                  :variant="appointment_data.type == 1 ? 'success' : 'primary'"
                  class="text-capitalize"
                >
                  {{ appointment_data.type == 1 ? "General People" : "Police" }}
                </b-badge>
              </td>
            </tr>
            <tr v-if="appointment_data.phone">
              <th class="align-middle">Number</th>
              <td class="align-middle">{{ appointment_data.phone }}</td>
            </tr>
            <tr v-if="appointment_data.email">
              <th class="align-middle">Email</th>
              <td class="align-middle">{{ appointment_data.email }}</td>
            </tr>
            <tr>
              <th class="align-middle">Desire Date & Time</th>
              <td>
                {{ appointment_data.desire_date_time | date_time_format }}
              </td>
            </tr>
            <tr>
              <th class="align-middle">Created At</th>
              <td>
                {{ appointment_data.created_at | date_time_format }}
              </td>
            </tr>
            <tr>
              <th class="align-middle">Is Approved</th>
              <td class="align-middle">
                <b-badge
                  :variant="
                    appointment_data.is_approved == 1
                      ? 'success'
                      : appointment_data.is_approved == 2
                      ? 'warning'
                      : 'danger'
                  "
                  class="text-capitalize"
                >
                  {{
                    appointment_data.is_approved == 1
                      ? "Approved"
                      : appointment_data.is_approved == 2
                      ? "Pending"
                      : "Reject"
                  }}
                </b-badge>
              </td>
            </tr>
            <tr>
              <th class="align-middle">Total Person</th>
              <td class="align-middle">
                {{ appointment_data.how_many_person }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>Appointment Purposes : </b>
                {{ appointment_data.appointment_purposes }}
              </td>
            </tr>

            <tr v-if="appointment_data.nid">
              <th class="align-middle">NID</th>
              <td class="align-middle">{{ appointment_data.nid }}</td>
            </tr>

            <tr v-if="appointment_data.email">
              <th class="align-middle">Gender & Age</th>
              <td class="align-middle">
                {{
                  appointment_data.gender == 1
                    ? "Male"
                    : appointment_data.gender == 2
                    ? "Female"
                    : "Other"
                }}
                <span v-if="appointment_data.age"
                  >(Age : {{ appointment_data.age }})</span
                >
              </td>
            </tr>
            <tr v-if="appointment_data.present_address">
              <th class="align-middle">Present Address</th>
              <td class="align-middle">
                {{ appointment_data.present_address }}
              </td>
            </tr>
            <tr v-if="appointment_data.permanent_address">
              <th class="align-middle">Present Address</th>
              <td class="align-middle">
                {{ appointment_data.permanent_address }}
              </td>
            </tr>
            <tr>
              <td colspan="2" class="align-middle">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <flat-pickr
                      v-if="manage_permission"
                      v-model="appoint_update.date"
                      class="form-control"
                      placeholder="Select Reschedule Date and Time"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }"
                    />
                  </div>
                  <div class="col-md-6 text-right">
                    <b-button
                      v-if="
                        appointment_data.is_approved != 1 && manage_permission
                      "
                      size="sm"
                      variant="success"
                      class="mr-1"
                      @click="appointmentApproved(1)"
                    >
                      Approved
                    </b-button>
                    <b-button
                      v-if="
                        appointment_data.is_approved != 3 && manage_permission
                      "
                      size="sm"
                      variant="danger"
                      class="mr-1"
                      @click="appointmentApproved(3)"
                    >
                      Reject
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="Close()"
                    >
                      Close
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BCardText,
  BLink,
  BFormInput,
  BButton,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
export default {
  props: ["manage_permission"],
  data() {
    return {
      appointmentModle: false,
      search: {
        search_data: "",
        type: null,
        status: null,
        paginate: 10,
        page: 1,
      },
      types: [
        {
          value: 1,
          name: "General People",
        },
        {
          value: 2,
          name: "Police",
        },
      ],
      status: [
        {
          value: 1,
          name: "Approved",
        },
        {
          value: 2,
          name: "Pending",
        },
        {
          value: 3,
          name: "Reject",
        },
      ],
      appoint_update: {
        id: null,
        date: "",
        status: null,
      },
      perPageOptions: [10, 25, 50, 100],

      appointments: {},
      appointment_data: {},
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const appointments = await this.callApi(
        "post",
        "/app/get/appointments?page=" + this.search.page,
        this.search
      );
      if (appointments.status == 200) {
        this.appointments = appointments.data.appointments;
      }
    },

    async getAppointmentModel(id) {
      this.appointment_data = {};
      this.appoint_update.id = null;
      this.appoint_update.date = "";
      this.appoint_update.status = null;
      const appointment = await this.callApi(
        "post",
        "/app/get/appointment/show/" + id
      );
      if (appointment.status == 200) {
        this.appoint_update.id = id;
        this.appointment_data = appointment.data.appointment;
        this.appointmentModle = true;
      }
    },

    async appointmentApproved(status) {
      this.appoint_update.status = status;
      const appointment = await this.callApi(
        "post",
        "/app/appointment/status/manage",
        this.appoint_update
      );
      if (appointment.status == 200) {
        this.getResults();
        this.appoint_update.date = "";
        this.appoint_update.status = null;
        this.appointment_data = appointment.data.appointment;
        this.s(appointment.data.message);
      }
    },

    Close() {
      this.appointment_data = {};
      this.appoint_update.date = "";
      this.appoint_update.status = null;
      this.appointmentModle = false;
    },

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
  },
  created() {
    setInterval(() => {
      if (this.$route.path == "/dashboard") {
        this.getResults();
      }
    }, 300000);
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    vSelect,
    BFormInput,
    BButton,
    BBadge,
    BRow,
    BCol,
    flatPickr,
  },
  watch: {
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.type": function (val) {
      this.SearchData();
    },
    "search.status": function (val) {
      this.SearchData();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
