<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <OverView></OverView>
    <AppointmentList
      v-if="permission.access"
      :manage_permission="permission.manage"
    ></AppointmentList>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import OverView from "./partials/OverView.vue";
import AppointmentList from "./partials/AppointmentList.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    AppointmentList,
    OverView,
  },
  data() {
    return {
      permission: {
        access: false,
        manage: false,
      },
    };
  },
  mounted() {
    this.Permission();
  },
  methods: {
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "appointment.access") {
          this.permission.access = true;
        }
        if (permission.slug == "approved.appointment") {
          this.permission.manage = true;
        }
      });
    },
  },
};
</script>

<style></style>
